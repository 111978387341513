<template>
  <div class="hello">
    <div v-if="authenticated">
      <h2 class="success">Hello {{ user.displayName }}</h2>
      <h2 v-if="user.isAnonymous">you are Anonymous</h2>
      <img :src="user.photoURL">
      <h4 class="success">Email verified!</h4>
      <p>Email address: {{ user.email }}</p>
    </div>
    <div v-else>
      <h2>Hello new user</h2>
      <span>Use google login</span>
      <button @click="loginButton">Login</button>
    </div>
  </div>
</template>

<script>
import firebase from '@/service/firebase'
import authProvider from '@/service/authProvider'
export default {
  name: 'FirebaseApp',
  computed: {
    computedUser: function () {
      return firebase.user
    }
  },
  data: () => ({
    authenticated: false,
    accessToken: null,
    user: {
      displayName: null,
      photoURL: null,
      email: null,
      emailVerified: null,
      isAnonymous: true
    }
  }),
  methods: {
    loginButton () {
      firebase.auth().signInWithPopup(authProvider).then(result => {
        // This gives you a Google Access Token.
        const token = result.credential.accessToken
        // The signed-in user info.
        const user = result.user
        console.log('logged in', {
          result, token, user
        })
        this.authenticated = true
        this.accessToken = token
        // this.user.authResponse = user
        this.user = user
      }).catch((err) => {
        console.log('login error', err)
        this.user.authResponse = err.message
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.success {
  color: mediumseagreen;
}
</style>
