<template>
  <div class="home">
    <FirebaseApp />
    <div align="center" style="margin-top: 3em">Check other page: <a href="https://maxlink.app" target="_blank">MaxLink.app</a></div>
  </div>
</template>

<script>
// @ is an alias to /src
import FirebaseApp from '@/components/FirebaseApp'

export default {
  name: 'Home',
  components: {
    FirebaseApp
  }
}
</script>
